import React from "react";
import { useState } from "react";
import "./InsightItem.css";
import downloadIcon from "../Contents/icons/SVG/download.svg"; // Path to your download icon
import arrowIcon from "../Contents/icons/SVG/arrow.svg"; // Path to your arrow icon

const InsightItem = () => {
    const [showAllEvents, setShowAllEvents] = useState(false);
  
    const events = [
      {
        title: "	STEP Wyoming Conference",
        date: "10/03/2025 - 10/04/2025",
        description: "Location: Jackson Hole, WY",
        link: "#",
      },
      {
        title: "WY State Bar Annual Meeting & Judicial Conference",
        date: "TBD",
        description: "TBD",
        link: "#",
      },
      {
        title: "Financial Freedom Summit",
        date: "Mar 5, 2024",
        description: "Explore strategies for financial independence.",
        link: "#",
      },
      {
        title: "Wealth Management Expo",
        date: "Apr 12, 2024",
        description: "Networking event for wealth professionals.",
        link: "#",
      },
    ];
  
    const toggleShowAllEvents = () => {
      setShowAllEvents(!showAllEvents);
    };
    const [openIndex, setOpenIndex] = useState(null); // Track which question is open

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Open/close the clicked question
  };

  const faqs = [
    {
      question: "What is the difference between a directed trustee and a discretionary trustee?",
      answer: `
        <p>
        A <strong>trustee</strong> is the person or entity currently responsible for managing and administering the trust according to its terms and the grantor's wishes. They handle duties such as investing assets, distributing funds to beneficiaries, and ensuring compliance with legal requirements.
        </p>
        <p>
        A <strong>successor trustee</strong> is designated to take over the trustee's responsibilities in the future. This usually occurs when the current trustee is unable to continue due to resignation, incapacity, or death. The successor trustee ensures continuity in trust management without the need for court intervention.
        </p>
        `,
    },
    {
      question: "How do I choose the right trust structure for my family?",
      answer: `
        <strong>Choosing the right trust structure involves considering your family's specific needs, financial goals, and the nature of your assets. Factors to consider include:</strong>
        <br />
        <ol>
          <li><strong>Purpose of the Trust:</strong> Determine whether you need asset protection, tax planning, charitable giving, or estate planning.</li>
          <li><strong>Types of Assets:</strong> Consider the nature of your assets (e.g., real estate, business interests, investments) and their management requirements.</li>
          <li><strong>Flexibility and Control:</strong> Decide how much control you want over the trust assets and distributions.</li>
          <li><strong>Beneficiaries' Needs:</strong> Take into account the financial needs, ages, and circumstances of your beneficiaries.</li>
          <li><strong>Legal and Tax Implications:</strong> Consult with legal and financial advisors to understand the implications of different trust structures.</li>
        </ol>
      `,
    },
    {
        question: "What are the benefits of establishing a trust in Wyoming?",
        answer: `
          <strong>Establishing a trust in Wyoming offers several benefits, including:</strong>
          <ol>
            <li><strong>Favorable Trust Laws:</strong> Wyoming has some of the most progressive and flexible trust laws in the U.S., allowing for directed trusts, dynasty trusts, and asset protection trusts.</li>
            <li><strong>No State Income Tax:</strong> Wyoming does not impose state income tax, which can result in significant tax savings</li>
            <li><strong>Privacy:</strong> Wyoming provides strong privacy protections for trust documents and proceedings, ensuring confidentiality for trust creators and beneficiaries.</li>
            <li><strong>Asset Protection:</strong> Wyoming trusts offer robust asset protection features to safeguard assets from creditors and legal claims.</li>
            <li><strong>Trustee Flexibility:</strong> The state allows for the appointment of both professional and non-professional trustees, providing flexibility in trust management.</li>
          </ol>
        `,
    },
    {
        question: "How can Dunham Private Trust assist with the management of complex assets like businesses or real estate?",
        answer: `
          <strong>Dunham Private Trust specializes in managing complex assets, including businesses and real estate. We offer:</strong>
          <ol>
            <li><strong>Expert Management:</strong> Our team has extensive experience in handling various asset types, ensuring professional and efficient management.</li>
            <li><strong>Customized Strategies:</strong> We develop tailored strategies to meet the specific needs and goals of your complex assets.</li>
            <li><strong>Administrative Support:</strong> We handle all administrative tasks, including bookkeeping, tax compliance, and record-keeping.</li>
            <li><strong>Valuation and Appraisal:</strong> We provide valuation and appraisal services to ensure your assets are appropriately valued.</li>
            <li><strong>Collaboration:</strong> We work closely with your existing advisors and family members to ensure a cohesive approach to asset management.</li>
          </ol>
        `,
    },
    {
        question: "What are the tax advantages of setting up a trust in Wyoming?",
        answer: `
          <strong>Setting up a trust in Wyoming offers several tax advantages:</strong>
          <ol>
            <li><strong>No State Income Tax:</strong> Wyoming does not levy state income tax on trust income, which can lead to significant savings.</li>
            <li><strong>Dynasty Trusts:</strong> Wyoming allows for the creation of dynasty trusts that can last for generations without being subject to estate or generation-skipping transfer taxes.</li>
            <li><strong>Reduced Capital Gains Tax:</strong> Trusts in Wyoming can potentially minimize capital gains tax liabilities through strategic asset management.</li>
            <li><strong>Favorable Tax Treatment:</strong> Wyoming's trust laws are designed to provide favorable tax treatment for both grantors and beneficiaries, maximizing the preservation of wealth.</li>
          </ol>
        `,
    },
    {
        question: "How do I transition an existing trust to Dunham Private Trust?",
        answer: `
          <strong>Transitioning an existing trust to Dunham Private Trust is a straightforward process:</strong>
          <ol>
            <li><strong>Review Trust Documents:</strong> Start by reviewing the existing trust documents to understand the terms and any specific provisions regarding trustee changes.</li>
            <li><strong>Consultation:</strong> Schedule a consultation with our experts to discuss your goals and the specifics of the existing trust.</li>
            <li><strong>Drafting Amendments:</strong> We will assist in drafting any necessary amendments or documents to facilitate the transition, ensuring compliance with legal requirements.</li>
            <li><strong>Coordination:</strong> Our team will coordinate with your current trustees and advisors to ensure a smooth and efficient transition.</li>
            <li><strong>Notification:</strong> Notify all relevant parties, including beneficiaries, of the trustee change and provide them with updated contact information.</li>
            <li><strong>Ongoing Support:</strong> Dunham Private Trust will provide ongoing support and management, ensuring your trust continues to operate effectively and in accordance with your wishes.</li>
          </ol>
        `,
    },


  ];
  
  


  return (
    <div>
      {/* Watch and Learn Section */}
      {/* <section className="insights-section">
        <div className="insights-header">
          <div className="header-title">
            <h2>Watch and Learn: Expert Videos</h2>
            <hr className="divider" />
          </div>
          <div className="header-description">
            <p>
              Our curated selection of videos covers key topics in trust
              services, estate planning, and wealth management. These expert-led
              videos provide valuable insights and practical advice to help you
              navigate your financial journey with confidence.
            </p>
          </div>
        </div>
        <div className="videos-container">
          <div className="video-block">
            <div className="video-placeholder"></div>
            <h3>Charitable Remainder Trust</h3>
            <p>
              Dunham Private Trust has expertise in setting up a charitable
              remainder trust (CRT) - a tax-deductible, irrevocable trust that
              allows donors to generate income and make charitable gifts.
            </p>
          </div>
          <div className="video-block">
            <div className="video-placeholder"></div>
            <h3>California vs NING: Prepare You for Client Meetings</h3>
            <p>
              Watch our leaders: Jeffrey Dunham and Ann Rosevear Esq. discuss
              California vs NING the new tax law and how to prepare you for
              client meetings.
            </p>
          </div>
        </div>
      </section> */}

      {/* In-Depth Guides and White Papers Section */}
      <section className="guides-section">
        <div className="guides-content">
          <div className="guides-text">
            <h2>In-Depth Guides and White Papers</h2>
            <hr className="divider" />
            <br/>
            <p>
              Gain deeper insights with our detailed guides and white papers.
              These resources are designed to provide you with comprehensive
              information on various aspects of trust services, legal
              considerations, and strategic planning.
            </p>
          </div>
          <div className="guides-links">
            <div className="guide-item">
              <a href="https://www.dunham.com/CloudOpen/OpenGuid/f07bd20a-ce7f-4911-9df3-f55ccfd597ca" download className="guide-download">
                <img src={downloadIcon} alt="Download Icon" className="guide-icon" />
                <span>Download: Nevada-Wyoming Spousal Lifetime Access Trust (SLAT) Advantage.</span>
              </a>
            </div>
            {/* <div className="guide-item">
              <img src={downloadIcon} alt="Download Icon" className="guide-icon" />
              <span>Using a Revocable Living Trust to Exercise More Control</span>
            </div> */}
          </div>
        </div>
      </section>
      {/* Upcoming Events Section */}
      <section className="events-section">
      <div className="events-header">
        <h2>Upcoming Events</h2>
        <hr className="divider" />
      </div>
      <div className="events-container">
        {events
          .slice(0, showAllEvents ? events.length : 2)
          .map((event, index) => (
            <div key={index} className="event-block">
              <div className="event-details">
                <h3>{event.title}</h3>
                <p className="event-date">{event.date}</p>
                <p className="event-description">{event.description}</p>
              </div>
              <div className="event-action">
                <a href={event.link} className="visit-site-button">
                  Visit Site
                </a>
              </div>
            </div>
          ))}
      </div>
      <div className="show-more-container">
        <button onClick={toggleShowAllEvents} className="show-more-button">
          {showAllEvents ? "Show Less" : "Show More"}
        </button>
      </div>
    </section>
    <section className="faq-section">
  <div className="faq-header">
    <h2>Frequently Asked Questions</h2>
    <hr className="divider" />
    <p>
      Find answers to the most commonly asked questions about trust services,
      estate planning, and more. Our FAQ section is a valuable resource for
      quick information and guidance on various topics.
    </p>
  </div>
  <div className="faq-container">
    {faqs.map((faq, index) => (
      <div key={index} className="faq-item">
        <div className="faq-question" onClick={() => toggleQuestion(index)}>
          <span>{faq.question}</span>
          <span className="faq-icon">{openIndex === index ? "▲" : "▼"}</span>
        </div>
        {openIndex === index && (
          <div
            className="faq-answer"
            dangerouslySetInnerHTML={{ __html: faq.answer }}
          />
        )}
      </div>
    ))}
  </div>
</section>

    </div>
  );
};

export default InsightItem;
