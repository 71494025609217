import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import "./ContactItem.css";


const ContactItem = () => {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    account: "",
    question: "",
    additionalInfo: "",
  });

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
      console.log("Captcha verified successfully.");
    } else {
      setCaptchaVerified(false);
      console.error("Captcha verification failed.");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!captchaVerified) {
      alert("Please complete the reCAPTCHA verification.");
      return;
    }
  
    const payload = {
      from_name: `${formData.firstName} ${formData.lastName}`, // Matches {{from_name}}
      firstName: formData.firstName,                         // Matches {{firstName}}
      lastName: formData.lastName,                           // Matches {{lastName}}
      email: formData.email,                                 // Matches {{email}}
      phone: formData.phone,                                 // Matches {{phone}}
      account: formData.account,                             // Matches {{account}}
      question: formData.question,                           // Matches {{question}}
      additionalInfo: formData.additionalInfo,               // Matches {{additionalInfo}}
      //message: "This is a test message.",                    // Matches {{message}}
    };
    
  
    console.log("Payload being sent:", payload);
  
    try {
      const response = await emailjs.send(
        "service_71gae7i", // Your Service ID
        "template_ag8yrq9", // Your Template ID
        payload,
        "XrwBdwYTuiCkrlsjL" // Your Public Key/User ID
      );
  
      console.log("EmailJS Response:", response);
  
      if (response.status === 200) {
        alert("Email sent successfully!");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          account: "",
          question: "",
          additionalInfo: "",
        });
        setCaptchaVerified(false);
      } else {
        throw new Error("Failed to send email.");
      }
    } catch (error) {
      console.error("EmailJS Error:", error);
      alert("Failed to send email. Please try again later.");
    }
  };

  return (
    <div className="contact-wrapper">
      {/* <div className="image-container">
        <img src="/photos/images/contact.png" alt="Aesthetic decoration" />
      </div> */}
      <div className="contact-page">
        
        <div className="contact-info">
          <h1 className="contact-title">Get in Touch</h1>
          <div className="underline"></div>
          <h3>Contact</h3>
          <p>Phone: (888) 438-6426</p>
          <p>Fax: (775) 826-7904</p>
        </div>

        <div className="contact-form">
          <form onSubmit={handleSubmit}> 
            <div className="form-section">
              <h3>Personal Information</h3>
              <div className="input-group">
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="First Name"
                  className="input-field"
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="Last Name"
                  className="input-field"
                  required
                />
              </div>
              <div className="input-group">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email Address"
                  className="input-field"
                  required
                />
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Phone Number"
                  className="input-field"
                  required
                />
              </div>
              <input
                type="text"
                name="account"
                value={formData.account}
                onChange={handleInputChange}
                placeholder="Account # or Title"
                className="input-full"
                required
              />
            </div>

            <div className="form-section">
              <h3>Suggested Questions</h3>
              <select
                name="question"
                value={formData.question}
                onChange={handleInputChange}
                className="input-full"
                required
              >
                <option value="">Select a question...</option>
                <option value="Account">Question about my account</option>
                <option value="Creating new account">Assistance creating a new estate plan/trust</option>
                <option value="Existing account">Assistance with an existing estate plan/trust</option>
                <option value="General">General Questions</option>
              </select>
            </div>

            <div className="form-section">
              <h3>Additional Information</h3>
              <textarea
                name="additionalInfo"
                value={formData.additionalInfo}
                onChange={handleInputChange}
                placeholder="Additional information"
                className="textarea"
              ></textarea>
            </div>

            <div className="recaptcha">
              <ReCAPTCHA
                sitekey="6LfKBoQqAAAAAC6sgXkweMWRLCKKhukX95O5aP_6" // Replace with your site key
                onChange={handleCaptchaChange}
              />
            </div>

            {/* Submit Button */}
            <div
              role="button"
              onClick={handleSubmit}
              style={{
                display: "inline-block",
                backgroundColor: "#4a2f2f",
                color: "#fff",
                padding: "12px 24px",
                fontSize: "16px",
                fontWeight: "bold",
                borderRadius: "5px",
                textAlign: "center",
                cursor: "pointer",
                marginTop: "20px",
                userSelect: "none", // Prevent text selection
              }}
            >
              Submit
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactItem;
