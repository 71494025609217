import React from 'react';
import '../../App.css'
import Navbar from '../Navbar.js'
import InsightItem from '../InsightItem.js';
import InsightSection from '../InsightSection.js';
import Footer from '../Footer.js';

function Insights() {
    return (
        <>
            
            <InsightSection/>
            <InsightItem/> 
            <Footer/>
        </>
    );
}


export default Insights;
  