import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import logo from '../Contents/Logo/DPT Logo.svg';
import './Navbar.css';

function Navbar() {
    const location = useLocation();
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [navbarBackground, setNavbarBackground] = useState('navbar-transparent');

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
        window.addEventListener('resize', showButton);
        window.addEventListener('scroll', changeBackground);
        return () => {
            window.removeEventListener('resize', showButton);
            window.removeEventListener('scroll', changeBackground);
        };
    }, []);

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbarBackground('navbar-light-gray');
        } else {
            setNavbarBackground('navbar-transparent');
        }
    };

    return (
        <>
            <nav className={`navbar ${navbarBackground}`}>
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        <img src={logo} alt="Logo" className="navbar-logo-img" />
                    </Link>

                    {/* Hamburger Icon */}
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>

                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="nav-item">
                            <Link to="/WhyDunham" className="nav-links" onClick={closeMobileMenu}>
                                WHY DUNHAM
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/WhoWeServe" className="nav-links" onClick={closeMobileMenu}>
                                WHO WE SERVE
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Team" className="nav-links" onClick={closeMobileMenu}>
                                WHO WE ARE
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Insights" className="nav-links" onClick={closeMobileMenu}>
                                INSIGHTS
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Contact" className="nav-links" onClick={closeMobileMenu}>
                                CONTACT
                            </Link>
                        </li> 
                        {/* Mobile Login button */}
                        <li className="nav-item">
                            {/* <a
                                href="https://login.orionadvisor.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="nav-links-mobile"
                                onClick={closeMobileMenu}
                            >
                                LOGIN
                            </a> */}
                        </li>
                    </ul>

                    {/* Desktop Login button */}
                    {/* {button && (
                        <a
                            href="https://login.orionadvisor.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="nav-login-button"
                        >
                            LOGIN
                        </a>
                    )} */}
                </div>
            </nav>
        </>
    );
}

export default Navbar;
