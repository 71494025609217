import React from "react";
import '../App.css';
import './InsightSection.css';

function InsightSection() {
    return (
        <div className='hero-container'>
       
        <img src='/photos/images/insights.png' />

        <div className="banner-content" style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <h1 className="banner-title" style={{ color: 'white', width: '60%' }}>Insights</h1>
            <br />
            <p className="banner-paragraph" style={{ color: 'white', width: '60%' }}>
                Our Insights page is dedicated to providing you with the latest resources, expert advice, 
                and valuable tools to help you make informed decisions about your wealth and legacy. Explore our comprehensive 
                collection of articles, guides, white papers, and more to stay informed and empowered.
            </p>
            <br />
        </div>
    </div>
    );
}

export default InsightSection;